import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0];

export const dictionary = {
		"/": [9],
		"/auth": [10,[2]],
		"/auth/reset": [11,[2]],
		"/home": [12,[3]],
		"/insights": [13,[4]],
		"/lockscreen": [14],
		"/notebook": [15,[5]],
		"/notebook/chat": [16,[5]],
		"/notebook/journal": [17,[5]],
		"/notebook/journal/[slug]": [18,[5]],
		"/notebook/talk": [~19,[5]],
		"/popup": [20,[6]],
		"/resources": [21,[7]],
		"/settings": [22],
		"/therapists": [23],
		"/welcome": [24,[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';